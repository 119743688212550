@import '../../assets/styles/colors';

$font-color: white;

@mixin floatingText {
  position: relative;
  left: 50%;
  top: 50%;
  align-self: center;
  transform: translate(-50%, -50%);
  color: white;
  z-index: 2;
};

@mixin title-basic-style {
  color: $font-color;
  margin-block-end: 0.1em;
}

@mixin title-style-h1 {
  font-size: 5vh;
  @include title-basic-style;
}
@mixin title-style-h2 {
  font-size: 4vh;
  @include title-basic-style;
}
@mixin title-style-h3 {
  font-size: 3vh;
  @include title-basic-style;
}

h3 {
  color: $font-color;
  margin-block-start: 0.1em;
}

:local(.main-container) {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

:local(.scroll) {
  position: absolute;
  bottom: 5em;
  left: 50%;
  transform: translateX(-50%);
  color: $plum;
  font-weight: 500;
}

:local(.container) {
  position: relative;
  width: 25em;
  height: 900px;
}

.circle {
  height: 300px;
}

.page-one {
  flex: 1;
  align-items: center;
  justify-content: center;
}

h1 {
  @include title-style-h1();
}

h2 {
  @include title-style-h2()
}

h1.greeting {
  @include floatingText;
  font-size: 4.5vh;
}

h3.greeting {
  @include floatingText;
  font-size: 2vh;
  margin-block-start: 0.3em;
  margin-block-end: 1em;
}

section::after {
  position: absolute;
  bottom: 0;
  left: 0;
  content: '';
  width: 100%;
  height: 80%;
}

section h1 {
  position: absolute;
  top: 87%;
  left: 50%;
  z-index: 1;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  color: #fff;
  font : normal 300 24px/1 'Josefin Sans', sans-serif;
  text-align: center;
  white-space: nowrap;
}

#section05 { background: url(https://picsum.photos/1200/800?image=1037) center center / cover no-repeat;}

#section05 h1 {
  font-size: 3vh;
}

#section05 a {
  padding-top: 70px;
}
#section05 a span {
  position: absolute;
  top: 0;
  left: 50%;
  top: 90%;
  width: 24px;
  height: 24px;
  margin-left: -12px;
  border-left: 1px solid #fff;
  border-bottom: 1px solid #fff;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  -webkit-animation: sdb05 1.5s infinite;
  animation: sdb05 1.5s infinite;
  box-sizing: border-box;
}
@-webkit-keyframes sdb05 {
  0% {
    -webkit-transform: rotate(-45deg) translate(0, 0);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    -webkit-transform: rotate(-45deg) translate(-20px, 20px);
    opacity: 0;
  }
}
@keyframes sdb05 {
  0% {
    transform: rotate(-45deg) translate(0, 0);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    transform: rotate(-45deg) translate(-20px, 20px);
    opacity: 0;
  }
}

.detail-container {
  position: relative;
  width: 100vw;
  align-items: flex-start;
  justify-content: flex-start;
  align-self: flex-start;
  text-align: left;
  left: 0;
}

.detail-header {
  margin: 5vh 10vw;

  h1 {
    font-size: 5vh;
    color: $font-color;
    margin-block-end: 0.1em;
  }

  h3 {
    font-size: 2.85vh;
    color: $font-color;
    margin-block-start: 0.1em;
  }
}

.detail-about-me {
  // position: absolute;
  font-family: "Josefin Sans, sans-serif";
  font-style: italic;
  padding: 2vh 10vh 5vh 10vh;
  margin: 5vh 20vw;
  text-align: center;
  align-items: center;
  left: 0;
  background:rgba(0,0,0,0.3);

  h3 {
    font-weight: 300;
    font-size: 2.7vh;
  }
}

.footer-container {
  position: relative;
  width: 100vw;
  align-items: flex-start;
  justify-content: flex-start;
  align-self: flex-start;
  text-align: center;
  left: 0;
}

.footer-header {
  margin: 5vh 10vw;

  h1 {
    font-size: 5vh;
    color: black;
    margin-block-end: 0.1em;
  }

  h3 {
    color: black;
    margin-block-start: 0.1em;
  }
}

.footer-connections {
  font-family: "Josefin Sans, sans-serif";
  font-style: italic;
  // padding: 2vh 10vh 5vh 10vh;
  margin: 5vh 20vw;
  text-align: center;
  align-items: center;
  left: 0;

  .columns {
    display: flex;
    margin-bottom: 5vh;
    justify-content: center;
  }

  h3 {
    font-weight: 300;
  }
  span {
    line-height: 0px;

    margin-block-start: 0.1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
  }
}

.socmed-column {
  width: 50%;
  max-width: 50%;
}

.socmed-list {
  display: table;
}

.icon-tubmnail {
  display: table-cell;
}

.icon-desc {
  display: table-cell;
  text-align: start;
  padding-left: 0.4vw;
}

.socmed-list > * {
  vertical-align: middle;
  cursor: pointer;
}

.footter-copyright {
  padding-top: 1em;
  padding-bottom: 1em;
  margin-block-end: 0;
  background-color: lightgray;
  overflow: hidden;
}

.ard-img {
  height: 5vh;
  vertical-align: middle;
}


// const breakpoints = {
$extraSmallScreen: 360;
$smallScreen: 414;
$mediumScreen: 630;
$largeScreen: 960;
$iphone5Screen: 320;

@media (max-width: $mediumScreen - 1px) and (min-width: $extraSmallScreen +0px) {

  .detail-about-me {
    // position: absolute;
    font-family: "Josefin Sans, sans-serif";
    font-style: italic;
    padding: 2vh 3vh 2vh 3vh;
    margin: 2vh 3vw;
    text-align: center;
    align-items: center;
    left: 0;
    background:rgba(0,0,0,0.3);

    h3 {
      font-weight: 300;
      font-size: 2.7vh;
    }

    h2 {
      margin-block-start: 0.1em;
    }
  }

  .footer-connections {
    margin: 5vh 5vw;
  }

  .socmed-column {
    width: 50%;
  }

  .soscmed-icon {
    height: 5vh;
    padding-right: 2vw;
  }

  .socmed-list > * {
    strong {
      margin-right: 2vw;
    }
  }
}